import * as React from 'react';
import Table from '@mui/joy/Table';
import Sheet from "@mui/joy/Sheet";
import FormControl from "@mui/joy/FormControl";
import Select from "@mui/joy/Select";
import {Button, FormLabel} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import IconButton from '@mui/joy/IconButton';
import Option from '@mui/joy/Option';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from '@mui/joy/CircularProgress';
import Checkbox from "@mui/joy/Checkbox";
import Tooltip from "@mui/joy/Tooltip";
import {AssignToDialog} from "../dialog/AssignToDialog";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";

function EnhancedTableToolbar(props) {
    const {numSelected, setOpen, hadName, showOnlyHadName} = props;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                py: 1,
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                ...(!showOnlyHadName && numSelected > 0 && {
                    bgcolor: 'background.level1',
                }),
                borderTopLeftRadius: 'var(--unstable_actionRadius)',
                borderTopRightRadius: 'var(--unstable_actionRadius)',
            }}
        >
            {!showOnlyHadName ? <>
                {numSelected > 0 ? (
                    <Typography sx={{flex: '1 1 100%'}} component="div">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        level="body-lg"
                        sx={{flex: '1 1 100%'}}
                        id="tableTitle"
                        component="div"
                    >
                        {hadName}
                    </Typography>
                )}
                <Tooltip placement="left" size="sm" title="AssignTo" color="warning">
                    <IconButton variant="solid" color="primary" size="sm" disabled={!numSelected}
                                onClick={() => setOpen(true)}><AirlineStopsIcon/></IconButton>
                </Tooltip>
            </> : <Typography
                level="body-lg"
                sx={{flex: '1 1 100%'}}
                id="tableTitle"
                component="div"
            >
                {hadName}
            </Typography>}
        </Box>
    );
}

export function DynamicTable({columns, rows, count, maxHeight = "780px", perPage, success, checkBox = false, hadName, showHad = false, showOnlyHadName = false, setPagination, type, rerender}) {
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage);
    const [selected, setSelected] = React.useState([]);
    // console.log(selected)
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        setPagination(type, newPage, rowsPerPage)
    };

    const handleChangeRowsPerPage = (event, newValue) => {
        setRowsPerPage(parseInt(newValue.toString(), 10));
        setPage(0);
        setPagination(type, 0, parseInt(newValue.toString(), 10))
    };

    const getLabelDisplayedRowsTo = () => {
        if (rows?.length === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? rows?.length
            : Math.min(rows?.length, (page + 1) * rowsPerPage);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    function labelDisplayedRows({from, to, count}) {
        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    }

    // console.log({count, rows})
    return (
        <Sheet variant="outlined"
               sx={{width: '100%', overflowX: 'auto', maxHeight: maxHeight, boxShadow: 'sm', borderRadius: 'sm'}}>
            {showHad && <EnhancedTableToolbar numSelected={selected.length} setOpen={setOpen} hadName={hadName}
                                              showOnlyHadName={showOnlyHadName}/>}
            <Table hoverRow stickyHeader={true} stickyFooter={true} sx={{tableLayout: "auto"}}>
                <thead>
                <tr>
                    {checkBox && <th scope="row">
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleSelectAllClick}
                            slotProps={{
                                input: {
                                    'aria-label': 'select all desserts',
                                },
                            }}
                            sx={{verticalAlign: 'sub'}}
                        />
                    </th>}
                    {columns.map((column) => (
                        <th key={column.id} align={column.align}
                            style={{minWidth: column.minWidth, textAlign: "center"}}>
                            {column.label}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {success ? (count >= 1 ? rows
                        .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <tr
                                    onClick={(event) => !row?.assign_to_id && handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    style={
                                        isItemSelected
                                            ? {
                                                '--TableCell-dataBackground':
                                                    'var(--TableCell-selectedBackground)',
                                                '--TableCell-headBackground':
                                                    'var(--TableCell-selectedBackground)',
                                            }
                                            : {}
                                    }
                                    tabIndex={-1} key={row.id}
                                >
                                    {checkBox && <th scope="row">
                                        <Checkbox
                                            disabled={!!row?.assign_to_id}
                                            checked={isItemSelected}
                                            slotProps={{
                                                input: {
                                                    'aria-labelledby': labelId,
                                                },
                                            }}
                                            sx={{verticalAlign: 'top'}}
                                        />
                                    </th>}
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <td key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                    ? column.format(value)
                                                    : column.renderCell ? column.renderCell(row) ? column.renderCell(row) : "N/A" : value ?? "N/A"}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        }) : <tr role="checkbox" tabIndex={-1}>
                        <td colSpan={columns?.length}>
                            <Typography variant="h6" sx={{textAlign: "center"}}>No Record
                                Found</Typography></td>
                    </tr>) :
                    <tr role="checkbox" tabIndex={-1}>
                        <td colSpan={columns?.length}>
                            <Typography variant="h6" sx={{textAlign: "center"}}>
                                <CircularProgress variant="plain"/>
                            </Typography>
                        </td>
                    </tr>}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={checkBox ? columns?.length + 1 : columns?.length}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end',}}>
                            <FormControl orientation="horizontal" size="sm">
                                <FormLabel>Rows per page:</FormLabel>
                                <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                                    <Option value={5}>5</Option>
                                    <Option value={8}>8</Option>
                                    <Option value={10}>10</Option>
                                    <Option value={12}>12</Option>
                                    <Option value={15}>15</Option>
                                    <Option value={20}>20</Option>
                                    <Option value={30}>30</Option>
                                    <Option value={40}>40</Option>
                                    <Option value={50}>50</Option>
                                    <Option value={60}>60</Option>
                                    <Option value={100}>100</Option>
                                </Select>
                            </FormControl>
                            <Typography textAlign="center" sx={{minWidth: 80}}>
                                {labelDisplayedRows({
                                    from: count === 0 ? 0 : page * rowsPerPage + 1,
                                    to: getLabelDisplayedRowsTo(),
                                    count: count === -1 ? -1 : count,
                                })}
                            </Typography>
                            <Box sx={{display: 'flex', gap: 1}}>
                                <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={page === 0}
                                    onClick={() => handleChangePage(page - 1)}
                                    sx={{bgcolor: 'background.surface'}}
                                >
                                    <KeyboardArrowLeftIcon/>
                                </IconButton>
                                <IconButton
                                    size="sm"
                                    color="neutral"
                                    variant="outlined"
                                    disabled={
                                        count !== -1
                                            ? page >= Math.ceil(count / rowsPerPage) - 1
                                            : false
                                    }
                                    onClick={() => handleChangePage(page + 1)}
                                    sx={{bgcolor: 'background.surface'}}
                                >
                                    <KeyboardArrowRightIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </td>
                </tr>
                </tfoot>
            </Table>
            {open && <AssignToDialog onClose={() => setOpen(false)} open={open} selectedRows={selected}
                                     rerender={() => rerender(hadName)}/>}
        </Sheet>
    );
}