import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function ShiftConfirmationDialog({open, onClose, renderer}) {
    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmation
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        Are you sure you want to start shift?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={onClose}>
                            Discard
                        </Button>
                        <Button variant="plain" color="success" onClick={renderer}>
                            Start Now
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}
