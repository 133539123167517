import React from "react";
import {
    Grid,
    IconButton,
    Chip,
    Box,
    Divider,
    Button,
    Input,
    Typography,
    Stack,
    Select,
    Option,
    Tooltip
} from "@mui/joy";
import axios from "axios";
import {WEB_API, WEB_API_IMAGE} from "../constants";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {CurrencyRupee, Search} from '@mui/icons-material';
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs";

dayjs.extend(utc);
import {styled} from '@mui/joy/styles';
import Sheet from "@mui/joy/Sheet";
import {DynamicTable} from "../components/DynamicTable";
import AspectRatio from "@mui/joy/AspectRatio";
import {ImagePreview} from "../dialog/ImagePreview";
import ConfirmationDialog from "../dialog/ConfirmationDialog";
import socket from "../socket/socket";
import CustomDateFilter from "../components/CustomDateFilter";
import SelectForSift from "../components/SelectForSift";
import SelectForUsers from "../components/SelectForUsers";

const Item = styled(Sheet)(({theme}) => ({
    ...theme.typography['body-lg'],
    textAlign: 'center',
    fontWeight: theme.fontWeight.md,
    color: theme.vars.palette.text.secondary,
    border: '1px solid',
    borderColor: theme.palette.divider,
    padding: theme.spacing(2),
    borderRadius: theme.radius.md,
    flexGrow: 1,
}));

export const PaymentDetails = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    let config = {headers: {'Authorization': 'Bearer ' + token}}
    const [paidAmount, setPaidAmount] = React.useState(0);
    const [conversationAmount, setConversationAmount] = React.useState(0);
    const [date, setDate] = React.useState('');
    const [payment, setPayment] = React.useState({rows: [], total: 0})
    const [verifiedPayment, setVerifiedPayment] = React.useState({rows: [], total: 0})
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [preview, setPreview] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [assignTo, setAssignTo] = React.useState('');
    const [shiftWise, setShiftWise] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [sifts, setSifts] = React.useState([]);
    const [open, setOpen] = React.useState({id: '', isOpen: false});
    const handlePagination = (key, page, limit) => {
        setPage(page);
        setLimit(limit);
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'lead_id', label: 'LeadId', align: 'center', minWidth: 70},
        {id: 'lead_name', label: 'Lead Name', align: 'center', minWidth: 70},
        {
            id: 'user_name',
            label: 'Assignee Name',
            minWidth: 70,
            align: 'center',
            renderCell: (parms) => {
                return (
                    <Box>
                        {parms?.user_name ?
                            <Chip sx={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} variant="outlined" color="primary">{parms?.user_name}</Chip> : "NA"}
                    </Box>
                )
            }
        },
        {
            id: 'screenshot',
            label: 'Screenshot',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                const url = WEB_API_IMAGE + params?.screenshot
                return <Box>
                    {params?.screenshot && <IconButton onClick={() => setPreview(url)}>
                        <AspectRatio ratio="1" sx={{width: 40}}>
                            <img
                                src={WEB_API_IMAGE + params?.screenshot}
                                srcSet={`${WEB_API_IMAGE + params?.screenshot} 2x`}
                                loading="lazy"
                                alt=""
                            />
                        </AspectRatio>
                    </IconButton> || "NA"}
                </Box>
            }
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Chip
                    variant="outlined"
                >
                    {params.amount}
                </Chip>
            }
        },
        {
            id: 'is_verified',
            label: 'Verify',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Button veriant="outlined" color={params?.is_verified ? "success" : "primary"}
                               onClick={() => setOpen({id: params?.id, isOpen: true})}>
                    {params?.is_verified ? "Verified" : "Verify"}
                </Button>
            }
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 100,
            align: 'center',
            renderCell: (parms) => {
                return <Box>{dayjs(`${parms?.createdAt}`).format("YYYY-MM-DD hh:mm A")}</Box>
            }
        }
    ];

    const paymentDetails = async () => {
        setSuccess(true)
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/payment-details?assignTo=${assignTo}&limit=${limit}&page=${page}&shiftWise=${shiftWise && JSON.stringify(shiftWise)}&customDate=${date && JSON.stringify(date)}`, config);
            if (status === 200 && type === "success") {
                setPayment({rows: data?.payments, total: data?.total})
                setSuccess(false)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(false);
        }
    };

    const verifiedPayments = async () => {
        setSuccess(true)
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/verified-payments?assignTo=${assignTo}&limit=${limit}&page=${page}&shiftWise=${shiftWise && JSON.stringify(shiftWise)}&customDate=${date && JSON.stringify(date)}`, config);
            if (status === 200 && type === "success") {
                setVerifiedPayment({rows: data?.payments, total: data?.total})
                setSuccess(false)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(false)
        }
    };

    const LeadsConversationAmount = async () => {
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/leads-conversation-amount?shiftWise=${shiftWise && JSON.stringify(shiftWise)}&customDate=${date && JSON.stringify(date)}&assignTo=${assignTo}`, config);
            if (status === 200 && type === "success") {
                setConversationAmount(data?.conversationAmount);
                setPaidAmount(data?.paidAmount);
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    React.useEffect(() => {
        if (shiftWise?.id) {
            LeadsConversationAmount()
            verifiedPayments()
            paymentDetails()
        }
    }, [assignTo, limit, page, shiftWise?.id, date]);


    React.useEffect(() => {
        socket.on("leadAmountShot", (data) => {
            if (data) {
                setPayment((pre) => {
                    let newArr = [data?.leadShot, ...(pre.rows)]
                    return {rows: newArr, total: pre?.total + 1}
                })
            }
        })

        return () => {
            socket.off("leadPaidShot");
        };
    }, [])

    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Grid container spacing={2}>
                <Grid xs={12} md={12}>
                    <Grid container justifyContent="space-between" sx={{margin: "10px"}}>
                        <Grid xs={12} md={4}><Typography level="h3">Payment Details</Typography></Grid>
                        <Grid xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={4}>
                                    <Input
                                        type="text"
                                        placeholder="Search by mobile"
                                        endDecorator={<IconButton><Search/></IconButton>}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <SelectForUsers values={users} setValues={setUsers} setAssignTo={setAssignTo}/>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <SelectForSift values={sifts} setValues={setSifts} setShiftWise={setShiftWise}/>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <CustomDateFilter values={date} setValues={setDate}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Paid Leads Amount:-> <CurrencyRupee fontSize="16px"/>{paidAmount || 0}</Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Leads Conversation Amount:-> <CurrencyRupee fontSize="16px"/>{conversationAmount || 0}</Item>
                </Grid>
                <Grid xs={12} md={4}>
                    <Item>Total Amount:-> <CurrencyRupee fontSize="16px"/>{paidAmount + conversationAmount}</Item>
                </Grid>
                <Grid xs={12} md={6}>
                    <DynamicTable
                        hadName="UnVerified Payment" maxHeight="640px" columns={columns}
                        count={payment?.total} rows={payment?.rows} perPage={limit}
                        success={!success} checkBox={false} showHad={true} showOnlyHadName={true}
                        setPagination={handlePagination}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <DynamicTable
                        hadName="Verified Payment" maxHeight="640px" columns={columns}
                        count={verifiedPayment?.total}
                        rows={verifiedPayment?.rows} perPage={limit}
                        success={!success} checkBox={false} showHad={true} showOnlyHadName={true}
                        setPagination={handlePagination}
                    />
                </Grid>
            </Grid>
            <ImagePreview open={preview} onClose={() => setPreview(false)}/>
            {open?.isOpen && <ConfirmationDialog open={open} onClose={() => setOpen({id: "", isOpen: false})}
                                                 rerender={verifiedPayments} rerender2={paymentDetails}/>}
        </Box>
    )
}