import * as React from 'react';
import {List, ListSubheader, ListItem, ListItemButton, ListItemDecorator, ListItemContent, Badge} from '@mui/joy';
import {CurrencyRupee, Dashboard, GroupAdd, Diversity3,AccessAlarm} from '@mui/icons-material';
import {Link} from 'react-router-dom';

export default function Navigations({leadCount, startSift, date}) {
    const activeValue = localStorage.getItem("activeList");
    const handleActiveList = (listValue) => {
        return +activeValue === listValue
    }
    const handleChange = (value) => {
        localStorage.setItem("activeList", value)
    }
    return (
        <List size="sm" sx={{'--ListItem-radius': '8px', '--List-gap': '4px'}}>
            <ListItem nested>
                <ListSubheader sx={{letterSpacing: '2px', fontWeight: '800'}}>
                    Browse
                </ListSubheader>
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{
                        '& .JoyListItemButton-root': {p: '8px'},
                    }}
                >
                    <Link to="/dashboard" style={{textDecoration: "none"}}>
                        <ListItem>
                            <ListItemButton selected={handleActiveList(1)} onClick={() => handleChange(1)}>
                                <ListItemDecorator>
                                    <Dashboard fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>Dashboard</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to="/users" style={{textDecoration: "none"}}>
                        <ListItem>
                            <ListItemButton selected={handleActiveList(2)} onClick={() => handleChange(2)}>
                                <ListItemDecorator>
                                    <GroupAdd fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>Users</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to="/leads" style={{textDecoration: "none"}}>
                        <ListItem>
                            <ListItemButton selected={handleActiveList(3)}
                                            onClick={() => handleChange(3)}>
                                <ListItemDecorator>
                                    <Diversity3 fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>Paid Leads</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to="/payment-details" style={{textDecoration: "none"}}>
                        <ListItem>
                            <ListItemButton selected={handleActiveList(4)} onClick={() => handleChange(4)}>
                                <ListItemDecorator>
                                    <CurrencyRupee fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>Payment Details</ListItemContent>
                                <ListItemDecorator>
                                    <Badge badgeContent={leadCount} max={999} badgeInset="0 -12px 0 0" color="success"/>
                                </ListItemDecorator>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to="/unpaid-up" style={{textDecoration: "none"}}>
                        <ListItem>
                            <ListItemButton color="danger" selected={handleActiveList(5)}
                                            onClick={() => handleChange(5)}>
                                <ListItemDecorator>
                                    <Diversity3 fontSize="small"/>
                                </ListItemDecorator>
                                <ListItemContent>Unpaid/Failed/Created Leads</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <ListItem>
                        <ListItemButton color="success" selected={handleActiveList(6)}
                                        onClick={() => {
                                            handleChange(6);
                                            startSift()
                                        }}>
                            <ListItemDecorator>
                                <AccessAlarm fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Start Sift</ListItemContent>
                            <ListItemDecorator>
                                {date}
                            </ListItemDecorator>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    );
}