import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";

export default function ConfirmationDialog({open, onClose, rerender,rerender2}) {
    const token = localStorage.getItem("token")
    const [isLoading, setIsLoading] = React.useState(false)
    const leadVerified = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            let config = {headers: {'Authorization': 'Bearer ' + token}}
            let {status, data: {message}} = await axios.put(`${WEB_API}admin/update/verified-lead`, {
                id: open?.id
            }, config);
            if (status === 200 || 201) {
                enqueueSnackbar(`${message}`, {variant: 'success'})
                setIsLoading(false);
                onClose()
                rerender();
                rerender2();
            }
        } catch (e) {
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
            setIsLoading(false)
        }
    };
    return (
        <React.Fragment>
            <Modal open={open?.isOpen} onClose={onClose}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        Confirmation
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        Are you sure you want to discard all of your notes?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="success" loading={isLoading} onClick={leadVerified}>
                            Verify Now
                        </Button>
                        <Button variant="plain" color="neutral" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}
