import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Typography,
    Avatar,
    Input,
    IconButton,
    Button,
    Tooltip,
} from '@mui/joy';
import SendIcon from '@mui/icons-material/Send';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import MicIcon from '@mui/icons-material/Mic';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ChatHeader from "./ChatHeader";
import Stack from "@mui/joy/Stack";

const WhatsAppChatUI = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [messages, setMessages] = useState([
        {id: 1, type: 'text', content: 'Hey, how are you?', time: '10:30 AM', sender: 'other', date: 'Saturday'},
        {id: 1, type: 'text', content: 'Hey, how are you?', time: '10:30 AM', sender: 'other', date: 'Saturday'},
        {id: 2, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'me'},
        {id: 3, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'me'},
        {id: 4, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'other'},
        {id: 5, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'me'},
        {id: 6, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'other'},
        {id: 7, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'other'},
        {id: 8, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'me'},
        {id: 9, type: 'text', content: "I'm good, thanks! What about you?", time: '10:32 AM', sender: 'me'},
        {id: 10, type: 'image', content: 'https://via.placeholder.com/150', time: '10:40 AM', sender: 'other'},
        {id: 11, type: 'voice', content: 'voice.mp3', time: '10:45 AM', sender: 'other'},
        {id: 12, type: 'image', content: 'https://via.placeholder.com/150', time: '10:40 AM', sender: 'me'},
    ]);

    const [messageText, setMessageText] = useState('');
    const chatEndRef = useRef(null); // Create a ref for the chat area

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Scroll to the bottom of the chat area
        chatEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [messages]); // Trigger when messages change

    const handleSend = () => {
        if (messageText.trim()) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    id: prevMessages.length + 1,
                    type: 'text',
                    content: messageText,
                    time: new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
                    sender: 'me'
                },
            ]);
            setMessageText('');
        }
    };

    const renderMessage = (message) => {
        switch (message.type) {
            case 'text':
                return (
                    <Box
                        key={message.id}
                        sx={{
                            display: 'flex',
                            justifyContent: message.sender === 'me' ? 'flex-end' : 'flex-start',
                            marginBottom: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '60%',
                                padding: '8px',
                                backgroundColor: message.sender === 'me' ? '#dcf8c6' : '#fff',
                                borderRadius: '8px',
                                borderTopLeftRadius: message.sender === 'me' ? '8px' : '0px',
                                borderTopRightRadius: message.sender === 'me' ? '0px' : '8px',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                                overflowWrap: 'break-word',
                            }}
                        >
                            <Typography sx={{fontSize: '14px', color: '#000'}}>{message.content}</Typography>
                            <Typography
                                level="body2"
                                sx={{fontSize: '12px', color: '#999', textAlign: 'right', marginTop: '4px'}}
                            >
                                {message.time}
                            </Typography>
                        </Box>
                    </Box>
                );
            case 'image':
                return (
                    <Box
                        key={message.id}
                        sx={{
                            display: 'flex',
                            justifyContent: message.sender === 'me' ? 'flex-end' : 'flex-start',
                            marginBottom: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '60%',
                                borderRadius: '8px',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                            }}
                        >
                            <img
                                src={message.content}
                                alt="Message Image"
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                }}
                            />
                            <Typography
                                level="body2"
                                sx={{fontSize: '12px', color: '#999', textAlign: 'right', marginTop: '4px'}}
                            >
                                {message.time}
                            </Typography>
                        </Box>
                    </Box>
                );
            case 'voice':
                return (
                    <Box
                        key={message.id}
                        sx={{
                            display: 'flex',
                            justifyContent: message.sender === 'me' ? 'flex-end' : 'flex-start',
                            marginBottom: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '60%',
                                padding: '8px',
                                backgroundColor: message.sender === 'me' ? '#dcf8c6' : '#fff',
                                borderRadius: '8px',
                                borderTopLeftRadius: message.sender === 'me' ? '8px' : '0px',
                                borderTopRightRadius: message.sender === 'me' ? '0px' : '8px',
                                boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton sx={{marginRight: '10px'}}>
                                <PlayArrowIcon/>
                            </IconButton>
                            <Typography sx={{fontSize: '14px', color: '#000', flexGrow: 1}}>
                                Voice Message
                            </Typography>
                            <Typography
                                level="body2"
                                sx={{fontSize: '12px', color: '#999', textAlign: 'right', marginTop: '4px'}}
                            >
                                {message.time}
                            </Typography>
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '400px',
                height: windowHeight * 0.9,
                margin: '0 auto',
                border: '1px solid #ddd',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                fontFamily: 'Arial, sans-serif',
                backgroundImage: 'url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <ChatHeader/>
            {/* Chat Area */}
            <Box
                sx={{
                    flexGrow: 1,
                    padding: '10px',
                    display: "flex",
                    position: "relative",
                    overflowY: 'auto',
                    flexDirection: 'column-reverse',
                }}
            >
                <Stack justifyContent="flex-end">
                    <Box
                        sx={{
                            textAlign: 'center',
                            margin: '10px 0',
                            color: '#999',
                            fontSize: '12px',
                        }}
                    >
                        {messages[0].date}
                    </Box>
                    {messages.sort((a, b) => (a.id - b.id)).map((message) => renderMessage(message))}
                    <div ref={chatEndRef}/>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    padding: '10px',
                }}
            >
                <Input
                    placeholder="Message"
                    sx={{flex: 1, borderRadius: '20px',}}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSend();
                        }
                    }}
                    startDecorator={<IconButton size="sm" sx={{color: 'white', borderRadius: "50%"}}>
                        <EmojiEmotionsIcon/>
                    </IconButton>}
                    endDecorator={<Box>
                        <IconButton size="sm" sx={{color: 'white', borderRadius: "50%"}}>
                            <AttachFileIcon/>
                        </IconButton>
                        <IconButton size="sm" sx={{color: 'white', borderRadius: "50%", ml: 1}}>
                            <CurrencyRupeeIcon/>
                        </IconButton>
                        <IconButton size="sm" sx={{color: 'white', borderRadius: "50%", ml: 1}}>
                            <PhotoCameraIcon/>
                        </IconButton>
                    </Box>}
                />
                <IconButton
                    onClick={handleSend}
                    sx={{
                        backgroundColor: '#4caf50',
                        borderRadius: '50%',
                        marginLeft: "5px",
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#45a049', // Darker green on hover
                        },
                    }}
                >
                    <MicIcon sx={{color: "white"}}/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default WhatsAppChatUI;
