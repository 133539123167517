import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import {
    Stack,
    Badge,
    Avatar,
    Button,
    Tooltip,
    Menu,
    MenuItem,
    MenuButton,
    ListDivider,
    Dropdown,
    Drawer,
    ModalClose,
    DialogTitle,
    IconButton,
    Typography,
    Box,
} from '@mui/joy';
import {
    CurrencyRupee,
    Dashboard,
    GroupAdd,
    DarkModeRounded,
    LightModeRounded,
    SettingsRounded,
    MenuBookRounded,
    Replay,
    Diversity3,
    Score,
    MenuRounded, LogoutRounded, AccessAlarm, AlarmOff,
} from '@mui/icons-material';
import {Link, useNavigate} from 'react-router-dom'
import Navigation from './Navigations';
import {DebouncedInput} from "./DebounceInput";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import dayjs from "dayjs";
import ShiftConfirmationDialog from "../dialog/ShiftConfirmationDialog";

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <Box>
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    if (mode === 'light') {
                        setMode('dark');
                    } else {
                        setMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRounded style={{marginRight: "7px"}}/> :
                    <LightModeRounded style={{marginRight: "7px"}}/>}
                {mode === 'light' ? " " + "Dark Mode" : " " + "Light Mode"}
            </IconButton>
        </Box>
    );
}

export default function Header({leadCount, setDebouncedValue}) {
    const navigate = useNavigate()
    const token = localStorage.getItem("token");
    const siftDate = localStorage.getItem("siftStartDate");
    const loginUser = JSON.parse(localStorage.getItem("user"));
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState({open: false, renderer: null});
    const [siftStartDate, setSiftStartDate] = React.useState(siftDate);
    const activeValue = localStorage.getItem("activeList");

    const handleActiveList = (listValue) => {
        return +activeValue === listValue
    }
    const handleChange = (value) => {
        localStorage.setItem("activeList", value)
    }

    let config = {headers: {'Authorization': 'Bearer ' + token}}
    const logout = async () => {
        try {
            let {status, data: {message}} = await axios.delete(`${WEB_API}admin/auth/logout`, config);
            if (status === 200) {
                navigate("/sign-in");
                localStorage.clear()
                enqueueSnackbar(`${message}`, {variant: 'success'})
            }
        } catch (e) {
            if (e.response?.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
        }
    };
    const startSift = async () => {
        try {
            let {status, data: {message}} = await axios.post(`${WEB_API}admin/create/sift`, {}, config);
            if (status === 200 || 201) {
                setSiftStartDate(dayjs().format('YYYY/MM/DD hh:mm A'))
                localStorage.setItem("siftStartDate", dayjs().format('YYYY/MM/DD hh:mm A'))
                enqueueSnackbar(`${message}`, {variant: 'success'})
            }
        } catch (e) {
            if (e.response?.status === 401) {
                localStorage.clear();
                navigate("/sign-in");
            }
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
        }
    };

    const reload = () => {
        window.location.reload();
    }

    return (
        <Box sx={{
            width: "100%",
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            padding: "20px",
            borderBottom: "1px solid #dde7ee"
        }}
        >
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{display: {xs: 'none', sm: 'flex'}}}
            >
                <Score sx={{fontSize: 35, color: "rgb(216, 91, 83)"}}/>
                <Link to="/dashboard" onClick={() => handleChange(1)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(1)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<Dashboard/>}
                    >
                        Dashboard
                    </Button>
                </Link>
                <Link to="/users" onClick={() => handleChange(2)}>
                    <Button
                        variant="plain"
                        color="neutral"
                        aria-pressed={handleActiveList(2)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<GroupAdd/>}
                    >
                        Users
                    </Button>
                </Link>
                <Link to="/leads" onClick={() => handleChange(3)}>
                    <Button
                        variant="plain"
                        color="success"
                        aria-pressed={handleActiveList(3)}
                        size="sm"
                        sx={{alignSelf: 'center'}}
                        startDecorator={<Diversity3/>}
                    >
                        Paid Leads
                    </Button>
                </Link>
                <Link to="/unpaid-up" onClick={() => handleChange(5)}>
                    <Tooltip title="Unpaid/Failed/Created Leads">
                        <Button
                            variant="plain"
                            color="danger"
                            aria-pressed={handleActiveList(5)}
                            size="sm"
                            sx={{alignSelf: 'center'}}
                            startDecorator={<Diversity3/>}
                        >
                            U/F/C Leads
                        </Button>
                    </Tooltip>
                </Link>
                <Link to="/payment-details" onClick={() => handleChange(4)}>
                    <Badge badgeContent={leadCount} max={999} badgeInset="0 -12px 0 0" color="success">
                        <Button
                            variant="plain"
                            color="neutral"
                            aria-pressed={handleActiveList(4)}
                            size="sm"
                            sx={{alignSelf: 'center'}}
                            startDecorator={<CurrencyRupee/>}
                        >
                            Payment Details
                        </Button>
                    </Badge>
                </Link>
            </Stack>
            <Box sx={{display: {xs: 'inline-flex', sm: 'none'}}}>
                <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
                    <MenuRounded/>
                </IconButton>
                <Drawer sx={{display: {xs: 'inline-flex', sm: 'none'}}} open={open} onClose={() => setOpen(false)}>
                    <ModalClose/>
                    <DialogTitle>WA Analysis</DialogTitle>
                    <Box sx={{px: 1}}>
                        <Navigation leadCount={leadCount} startSift={startSift} date={siftStartDate}/>
                    </Box>
                </Drawer>
            </Box>
            <Box sx={{display: {xs: 'none', sm: 'flex'}, flexDirection: 'row', gap: 1.5, alignItems: 'center',}}>
                <Button variant="outlined" color="success"
                        onClick={() => setOpenConfirmation({open: true, renderer: startSift})}
                        startDecorator={<AccessAlarm/>}>
                    Start Sift
                </Button>
                <Box>
                    <Tooltip title="Start Sift Date Time">
                        <Typography>
                            {siftStartDate}
                        </Typography>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1.5, alignItems: 'center',}}>
                <DebouncedInput setDebouncedValue={setDebouncedValue}/>
                <Tooltip title="Refresh">
                    <IconButton onClick={reload}>
                        <Replay/>
                    </IconButton>
                </Tooltip>

                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="sm"
                        sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}>
                        <Avatar src="" srcSet="" sx={{maxWidth: '32px', maxHeight: '32px'}}/>
                    </MenuButton>
                    <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{zIndex: '99999', p: 1, gap: 1, '--ListItem-radius': 'var(--joy-radius-sm)',}}
                    >
                        <MenuItem>
                            <Box sx={{display: 'flex', alignItems: 'center',}}>
                                <Avatar src="" srcSet="" sx={{borderRadius: '50%'}}/>
                                <Box sx={{ml: 1.5}}>
                                    <Typography level="title-sm" textColor="text.primary">
                                        {loginUser.name}
                                    </Typography>
                                    <Typography level="body-xs" textColor="text.tertiary">
                                        {loginUser.mobile}
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <ListDivider/>
                        <MenuItem>
                            <ColorSchemeToggle/>
                        </MenuItem>
                        <MenuItem>
                            <SettingsRounded/>
                            Settings
                        </MenuItem>
                        <ListDivider/>
                        <MenuItem onClick={logout} color="danger"> <LogoutRounded/> Log out </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>
            {openConfirmation?.open && <ShiftConfirmationDialog open={openConfirmation?.open}
                                                                onClose={() => setOpenConfirmation({
                                                                    open: false,
                                                                    renderer: null
                                                                })} renderer={openConfirmation?.renderer}/>}
        </Box>
    );
}