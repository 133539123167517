import React from "react";
import {Grid, IconButton, Chip, Box, Button, Typography, Stack, Select, Option, Input} from "@mui/joy";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {DynamicTable} from "../components/DynamicTable";
import {AssignToDialog} from "../dialog/AssignToDialog";
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import {useNavigate} from "react-router-dom";
import utc from "dayjs/plugin/utc"
import dayjs from "dayjs";

dayjs.extend(utc);
import socket from "../socket/socket";
import Link from "@mui/joy/Link";
import Tooltip from "@mui/joy/Tooltip";
import CustomDateFilter from "../components/CustomDateFilter";
import SelectForSift from "../components/SelectForSift";
import SelectForUsers from "../components/SelectForUsers";


export const UnpaidUp = ({debouncedValue}) => {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    const [open, setOpen] = React.useState(false);
    const [pagination, setPagination] = React.useState({failed: {page: 0, limit: 15, total: 0}})
    const [fail, setFail] = React.useState({rows: [], total: 0});
    const [sifts, setSifts] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [assignTo, setAssignTo] = React.useState('');
    const [shiftWise, setShiftWise] = React.useState('');
    const [date, setDate] = React.useState('');
    const [success, setSuccess] = React.useState({paid: false, failed: false});
    const [selectedRow, setSelectedRow] = React.useState([])

    const handlePaginatiopn = (key, page, limit) => {
        setPagination(prevState => {
            const newObje = {...prevState}
            newObje[key] = {...newObje[key], page, limit}
            return newObje;
        })
    }
    const copyToClipboard = (text) => {
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        enqueueSnackbar(`${text} Copied`, {variant: "success"})
        textField.remove()
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'name', label: 'Name', align: 'center', minWidth: 70},
        {
            id: 'mobile',
            label: 'Mobile',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (<Box sx={{cursor: "pointer"}} onClick={(e) => {
                        e.stopPropagation()
                        copyToClipboard(params?.mobile)
                    }}>
                        {params?.mobile}
                    </Box>
                )
            }
        },
        {
            id: 'assign_to_name',
            label: 'AssignTo',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>
                        {params?.assign_to_name ?
                            <Chip sx={{
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} variant="outlined" color="primary">{params?.assign_to_name}</Chip> :
                            <Tooltip placement="left" size="sm" title="AssignTo" color="warning">
                                <IconButton variant="solid" color="primary" disabled={!!params?.assign_to_id} size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setSelectedRow([params.id])
                                                setOpen(params.payment_status)
                                            }}><AirlineStopsIcon/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                )
            }
        },
        {
            id: 'lead_status',
            label: 'Status',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return <Box>
                    {params.lead_status && <Chip
                        variant="outlined"
                        sx={{borderRadius: "7px"}}
                        color={params.lead_status === "completed" ? "success" : "neutral"}
                    >
                        {params.lead_status}
                    </Chip> || "NA"}
                </Box>
            }
        },
        {
            id: 'visit',
            label: 'Visit',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Chip
                    variant="outlined"
                >
                    {params.visit}
                </Chip>
            }
        },
        {
            id: 'is_verified',
            label: 'Verify',
            minWidth: 50,
            align: 'center',
            renderCell: (params) => {
                return <Button veriant="outlined" color={params?.is_verified ? "success" : "primary"}>
                    {params?.is_verified ? `Verified-${params?.is_verified}` : "N/A"}
                </Button>
            }
        },
        {
            id: 'payment_gateway',
            label: 'Payment Gateway',
            minWidth: 100,
            align: 'center',
        },
        {
            id: 'createdAt',
            label: 'Created At',
            minWidth: 100,
            align: 'center',
            renderCell: (params) => {
                return <Box>{dayjs(`${params?.createdAt}`).format("YYYY-MM-DD hh:mm A")}</Box>
            }
        },
        {
            id: 'amount',
            label: 'Amount',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>{params?.amount ?? "NA"}</Box>
                )
            }
        },
        {
            id: 'campaign',
            label: 'Campaign',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>{params?.campaign ?? "NA"}</Box>
                )
            }
        },
        {
            id: 'creative_id',
            label: 'CreativeId',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>{params?.creative_id ?? "NA"}</Box>
                )
            }
        },
        {
            id: 'site_host',
            label: 'SiteHost',
            minWidth: 70,
            align: 'center',
            renderCell: (params) => {
                return (
                    <Box>{params?.site_host ?? "NA"}</Box>
                )
            }
        },
    ];

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
    const FailedLeads = async () => {
        setSuccess({...success, failed: true})
        try {
            let {status, data: {data, type}} = await axios.get(`${WEB_API}admin/retrieve/failed-leads?mobile=${debouncedValue}&limit=${pagination.failed.limit}&page=${pagination.failed.page}&assignTo=${assignTo}&shiftWise=${shiftWise && JSON.stringify(shiftWise)}&customDate=${date && JSON.stringify(date)}`, config);
            if (status === 200 && type === "success") {
                setFail({rows: data?.leads, total: data?.total})
                setSuccess({...success, failed: false})
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear();
                navigate("/sign-in");
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess({...success, failed: false});
        }
    };

    React.useEffect(() => {
        socket.on("lead", (data) => {
            if (data?.pa_status === "Failed") {
                FailedLeads()
            }
        })

        return () => {
            socket.off("lead")
        }
    }, [])

    React.useEffect(() => {
        shiftWise?.id && FailedLeads()
    }, [debouncedValue, pagination.failed.limit, pagination.failed.page, shiftWise.id, assignTo, date]);

    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Grid container spacing={2}>
                <Grid md={12}>
                    <Grid container justifyContent="space-between" sx={{margin: "10px"}}>
                        <Grid xs={12} md={4}><Typography level="h3">Unpaid/Failed/Created Leads</Typography></Grid>
                        <Grid xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={4}>
                                    <SelectForUsers values={users} setValues={setUsers} setAssignTo={setAssignTo}/>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <SelectForSift values={sifts} setValues={setSifts} setShiftWise={setShiftWise}/>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <CustomDateFilter values={date} setValues={setDate}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={12}>
                    <DynamicTable
                        columns={columns}
                        rows={fail.rows}
                        count={fail.total}
                        perPage={pagination.failed.limit}
                        success={!success.failed}
                        checkBox={!!fail.rows.length}
                        hadName={"Failed/Created/UnPaid"}
                        showHad={true}
                        type={"failed"}
                        setPagination={handlePaginatiopn}
                        rerender={(status) => {
                            status === "Failed" ? FailedLeads() : null
                        }}/>
                </Grid>
            </Grid>
            {!!open &&
            <AssignToDialog
                onClose={() => setOpen(false)}
                open={open}
                selectedRows={selectedRow}
                rerender={(status) => {
                    status === "Failed" ? FailedLeads() : null
                }}/>}
        </Box>
    )
}