import * as React from 'react';
import MenuButton from '@mui/joy/MenuButton';
import {FilterList, FilterListOff} from '@mui/icons-material';
import {
    Grid, Input, Tooltip, IconButton, Dropdown, Menu, Avatar,
    ListItemDecorator,
    MenuItem,
} from "@mui/joy";

export default function CustomDateFilter({values, setValues,setCustomDate}) {
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown>
            <Tooltip title={open ? "Close custom date filter" : "Open custom date filter"}>
                <MenuButton
                    slots={{root: IconButton}}
                    slotProps={{root: {variant: 'outlined', color: 'neutral'}}}
                    onClick={() => setOpen(!open)}
                >
                    {open ? <FilterListOff/> : <FilterList/>}
                </MenuButton>
            </Tooltip>
            <Menu
                open={open}
                variant="soft"
                aria-labelledby="apps-menu-demo"
            >
                <MenuItem focusVisibleClassName={"none"}>
                    <Grid container spacing={2}>
                        <Grid sx={12} md={6}>
                            <Tooltip title="Start Date Time">
                                <Input
                                    type="datetime-local"
                                    name="startDateTime"
                                    value={values?.startDateTime ?? ''}
                                    onChange={(e) => setValues({...values, startDateTime: e.target.value})}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid sx={12} md={6}>
                            <Tooltip title="End Date Time">
                                <Input
                                    type="datetime-local"
                                    name="endDateTime"
                                    value={values?.endDateTime ?? ''}
                                    onChange={(e) => setValues({...values, endDateTime: e.target.value})}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}
