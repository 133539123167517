import * as React from 'react';
import { Grid, Select, Option, Typography, Stack } from "@mui/joy";
import dayjs from "dayjs";
import axios from "axios";
import { WEB_API } from "../constants";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function SelectForSift({ values, setValues, setShiftWise }) {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [selectedValue, setSelectedValue] = React.useState(null); // State for selected value
    let config = { headers: { 'Authorization': 'Bearer ' + token } };

    const allSifts = async () => {
        try {
            let { status, data } = await axios.get(`${WEB_API}admin/retrieve/sifts`, config);
            if (status === 200) {
                setValues(data?.data?.sifts);
                if (data?.data?.sifts?.length > 0) {
                    setSelectedValue(data.data.sifts[0]); // Set the first value as the initial selected value
                    setShiftWise(data.data.sifts[0]); // Set the initial value for setShiftWise
                }
            }
        } catch (e) {
            if (e.response?.status === 401) {
                localStorage.clear();
                navigate("/sign-in");
            }
            enqueueSnackbar(`${e.response?.data?.message || 'Error fetching data'}`, { variant: 'error' });
        }
    };

    React.useEffect(() => {
        allSifts();
    }, []);

    const handleSelectChange = (e, value) => {
        setSelectedValue(value); // Update the selectedValue state
        setShiftWise(value); // Update the setShiftWise state
    };

    return (
        <Stack spacing={2} sx={{ alignItems: 'flex-start' }}>
            <Select
                placeholder="Search by Sift"
                name="sift"
                required
                sx={{ minWidth: 200 }}
                value={selectedValue ?? ""} // Bind the selectedValue state
                onChange={handleSelectChange} // Handle change event
            >
                {values && values.map((item, index) => (
                    <Option key={index} value={item}>
                        <Grid container>
                            <Grid md={5}>
                                <Typography level="body-sm">{item?.startId}</Typography>
                                <Typography level="body-xs">{dayjs(item?.startDateTime).format("lll")}</Typography>
                            </Grid>
                            <Grid md={1}>
                                -
                            </Grid>
                            <Grid md={5}>
                                <Typography level="body-sm">{item?.endId ?? 'still in progress'}</Typography>
                                <Typography level="body-xs">{dayjs(item?.endDateTime ?? new Date()).format("lll")}</Typography>
                            </Grid>
                        </Grid>
                    </Option>
                ))}
            </Select>
        </Stack>
    );
}
