import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import DialogContent from '@mui/joy/DialogContent';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogActions from "@mui/joy/DialogActions";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {Option, Select} from "@mui/joy";
import Grid from "@mui/joy/Grid";
import {useNavigate} from "react-router-dom";

export function UserDialog({open, onClose, user, setUser, rerender}) {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    const [isLoading, setIsLoading] = React.useState(false)
    const [select, setSelect] = React.useState({})
    const [cred, setCred] = React.useState({})

    const OnChange = (e, select_value) => {
        const {name, value} = e.target;
        setCred({
            ...cred,
            [name]: select_value ?? value
        })
    }

    const saveUser = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
            let {status, data: {message}} = await axios.post(user?.id ? `${WEB_API}admin/update/user` : `${WEB_API}admin/create/user`, {
                ...cred,
                ...select,
                id: user?.id,
                user_role_type: "client",
            }, config);
            if (status === 200 || 201) {
                enqueueSnackbar(`${message}`, {variant: 'success'});
                setIsLoading(false);
                rerender();
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear();
                navigate("/sign-in");
            }
            enqueueSnackbar(`${e.response?.data?.message}`, {variant: 'error'})
            setIsLoading(false)
        }
    };

    React.useEffect(() => {
        user.id && setCred(user)
        user.id && setSelect({"is_active": user?.is_active})

        return () => {
            setCred({});
            setUser({})
        }
    }, [user.id]);

    return (
        <React.Fragment>
            <Modal open={open} onClose={onClose}>
                <ModalDialog minWidth={"sm"}>
                    <DialogTitle>{user.id ? 'Update User' : 'Create User'}</DialogTitle>
                    <DialogContent>

                        <form
                            id="form1"
                            onSubmit={(event) => saveUser(event)}
                        >
                            <Grid container spacing={2} sx={{m: 0}}>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="text"
                                               name="name"
                                               value={cred?.name} autoFocus/>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Mobile</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="tel"
                                               name="mobile"
                                               value={cred?.mobile}/>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Password</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="password"
                                               name="password" value={cred?.password}/>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>OTP API Key</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="text"
                                               name="otp_api_key"
                                               value={cred?.otp_api_key}/>
                                    </FormControl>
                                </Grid>
                                {/*<Grid xs={12} md={6}>*/}
                                {/*    <FormControl>*/}
                                {/*        <FormLabel>WhatsApp API Key</FormLabel>*/}
                                {/*        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="text"*/}
                                {/*               name="whatsapp_api_token"*/}
                                {/*               value={cred?.whatsapp_api_token}/>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid xs={12} md={6}>*/}
                                {/*    <FormControl>*/}
                                {/*        <FormLabel>WhatsApp API Instance Id</FormLabel>*/}
                                {/*        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="text"*/}
                                {/*               name="whatsapp_api_instance_id"*/}
                                {/*               value={cred?.whatsapp_api_instance_id}/>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                {/*<Grid xs={12} md={6}>*/}
                                {/*    <FormControl>*/}
                                {/*        <FormLabel>WhatsApp Message template</FormLabel>*/}
                                {/*        <Input onChange={(e) => OnChange(e)} disabled={isLoading} type="text"*/}
                                {/*               name="whatsapp_api_template"*/}
                                {/*               value={cred?.whatsapp_api_template}/>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>User Type</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} type="text" name="user_type" disabled
                                               value={"client"}/>
                                    </FormControl>
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Threshold (In Secends)</FormLabel>
                                        <Input onChange={(e) => OnChange(e)} type="text" name="threshold"
                                               value={cred?.threshold}/>
                                    </FormControl>
                                </Grid>

                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Auto Assign</FormLabel>
                                        <Select
                                            slotProps={{listbox: {sx: {width: "100%"}}}}
                                            name="auto_assign"
                                            value={select?.auto_assign ? 1 : 0}
                                            onChange={(e, value) => setSelect({...select, "auto_assign": `${value}`})}
                                            placeholder="Select">
                                            <Option value={null} disabled>Select</Option>
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>InActive</Option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>

                                    <FormControl>
                                        <FormLabel>User Status</FormLabel>
                                        <Select
                                            slotProps={{listbox: {sx: {width: "100%"}}}}
                                            name="is_active"
                                            value={select?.is_active ? 1 : 0}
                                            onChange={(e, value) => setSelect({...select, "is_active": `${value}`})}
                                            placeholder="Select User Status">
                                            <Option value={null} disabled>Select User Status</Option>
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>InActive</Option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button form="form1" variant="plain" type="submit" loading={isLoading}>Submit</Button>
                        <Button variant="plain" color="danger" onClick={onClose}>Cancel</Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}