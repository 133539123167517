import * as React from 'react';
import {Grid, Select, Option, Typography, Stack,} from "@mui/joy";
import dayjs from "dayjs";
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";

export default function SelectForSift({values, setValues,setAssignTo}) {
    const navigate = useNavigate()
    const token = localStorage.getItem("token")
    let config = {headers: {'Authorization': 'Bearer ' + token}}
    const allUsers = async () => {
        try {
            let {status, data} = await axios.get(`${WEB_API}admin/retrieve/clients`, config);
            if (status === 200) setValues(data?.data)
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
        }
    };

    React.useEffect(() => {
        allUsers()
    }, []);

    return (
        <Stack spacing={2} sx={{alignItems: 'flex-start'}}>
            <Select
                placeholder="Search by assign user"
                name="assignTo"
                required
                sx={{minWidth: 200}}
                onChange={(e, value) => setAssignTo(value)}
            >
                {values && values.map((item) => {
                    return <Option value={item?.id}>{item?.name}</Option>
                })}
            </Select>
        </Stack>
    );
}
