import {io} from "socket.io-client";
import {WEB_API_SOCKET} from "../constants";

const loginUser = JSON.parse(localStorage.getItem("user"));

const socket = io(WEB_API_SOCKET, {
    autoConnect: true,
    auth: {
        username: loginUser?.id || ""
    },
})

socket.on("connection", () => {
    console.log("connected")
});

socket.onAny((event, ...arg) => {
    console.log(event, arg);
});


export default socket;