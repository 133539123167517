import React from "react";
import {EditNote} from '@mui/icons-material';
import {Typography, Grid, Button, IconButton, Tooltip, Box, Chip} from "@mui/joy";
import Switch, {switchClasses} from '@mui/joy/Switch';
import axios from "axios";
import {WEB_API} from "../constants";
import {enqueueSnackbar} from "notistack";
import {DynamicTable} from "../components/DynamicTable";
import {UserDialog} from "../dialog/UserDialog";
import {useNavigate} from 'react-router-dom';

export const User = ({debouncedValue}) => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [open, setOpen] = React.useState(false);
    // const [autoAssign, setAutoAssign] = React.useState({
    //     id:'',
    //     autoAssign:true
    // });
    const [page, setPage] = React.useState(0);
    const [limit, setLimit] = React.useState(15);
    const [rows, setRows] = React.useState({rows: [], total: 0});
    const [success, setSuccess] = React.useState(false);
    const [checked, setChecked] = React.useState({id: '', checked: false});
    const [user, setUser] = React.useState({});
    const handlePaginatiopn = (key, page, rowsPerPage) => {
        setPage(page)
        setLimit(rowsPerPage)
    }
    const columns = [
        {id: 'id', label: 'ID', align: 'center', minWidth: 70},
        {id: 'name', label: 'Name', align: 'center', minWidth: 70},
        {id: 'mobile', label: 'Mobile', minWidth: 70, align: 'center'},
        {id: 'otp_api_key', label: 'OTP Key', align: 'center', minWidth: 100},
        // {
        //     id: 'whatsapp_api_token', label: 'WhatsApp Key', align: 'center', minWidth: 100,
        //     renderCell: (parms) => {
        //         return parms?.whatsapp_api_token && parms?.whatsapp_api_token?.substring(0, 10) + "..." || "NA"
        //     }
        // },
        // {
        //     id: 'whatsapp_api_instance_id', label: 'WhatsApp Instance', align: 'center', minWidth: 100,
        //     renderCell: (parms) => {
        //         return parms?.whatsapp_api_instance_id && parms?.whatsapp_api_instance_id?.substring(0, 10) + "..." || "NA"
        //     }
        // },
        // {id: 'whatsapp_api_template', label: 'Template', align: 'center', minWidth: 100},
        {id: 'created_by_name', label: 'CreatedBy', align: 'center', minWidth: 100},
        // {id: 'total_complated', label: 'Completed', align: 'center', minWidth: 100},
        // {id: 'total_in_progress', label: 'In Progress', align: 'center', minWidth: 100},
        // {id: 'total_untouched', label: 'Untouched', align: 'center', minWidth: 100},
        {
            id: 'is_active', label: 'Active', align: 'center', minWidth: 70,
            renderCell: (params) => {
                return (
                    <Box>
                        <Chip
                            color={!params?.is_active ? "danger" : "success"}
                            variant="outlined"
                        >
                            {params?.is_active ? "Active" : "Inactive"}
                        </Chip>
                    </Box>
                )
            }
        },
        {id: 'user_type', label: 'User Type', align: 'center', minWidth: 70},
        // {
        //     id: 'threshold', label: 'Threshold', align: 'center', minWidth: 50,
        //     renderCell: (params) => {
        //         return (
        //             <Box>
        //                 {params?.threshold && <Chip
        //                     variant="solid"
        //                 >
        //                     {params?.threshold}s
        //                 </Chip> || "N/A"}
        //             </Box>
        //         )
        //     }
        // },
        {
            id: 'auto_assign', label: 'Auto Assign', align: 'center', minWidth: 50,
            renderCell: (params) => {
                return (<Tooltip color={params?.auto_assign ? 'success' : 'danger'} size="sm"
                                 title={params?.auto_assign ? 'Auto Assign On' : 'Auto Assign Off'}>
                        <Switch
                            color={params?.auto_assign ? 'success' : 'danger'}
                            checked={params?.auto_assign}
                            onChange={(event) => setChecked({id: params?.id, checked: event.target.checked})}
                            sx={{
                                '--Switch-thumbSize': '16px',
                                '--Switch-trackWidth': '40px',
                                '--Switch-trackHeight': '24px',
                                '--Switch-trackBackground': '#EE5E52',
                                '&:hover': {
                                    '--Switch-trackBackground': '#EE5E52',
                                },
                                [`&.${switchClasses.checked}`]: {
                                    '--Switch-trackBackground': '#5CB176',
                                    '&:hover': {
                                        '--Switch-trackBackground': '#5CB176',
                                    },
                                },
                            }}
                        />
                    </Tooltip>
                )
            }
        },
        {id: 'createdAt', label: 'Created At', minWidth: 100, align: 'center'},
        {
            id: 'action', label: 'Action', minWidth: 100, align: 'center',
            renderCell: (parms) => {
                return (
                    <Grid container specing={1} justifyContent="center">
                        <Tooltip placement="top" title="Edit">
                            <IconButton sx={{"--IconButton-size": "37px"}} onClick={() => {
                                setOpen(true);
                                setUser(parms)
                            }}>
                                <EditNote/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            }
        },
    ];


    let config = {headers: {'Authorization': 'Bearer ' + token}}
    const users = async () => {
        try {
            let {status, data: {data}} = await axios.get(`${WEB_API}admin/retrieve/users?mobile=${debouncedValue}&limit=${limit}&page=${page}`, config);
            if (status === 200) {
                setRows({rows: data?.users, total: data?.total})
                setSuccess(true)
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear()
                navigate("/sign-in")
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(true)
        }
    };

    React.useEffect(() => {
        users()
    }, [debouncedValue, page, limit]);


    const AutoAssign = async () => {
        try {
            let {status, data: {message}} = await axios.post(`${WEB_API}admin/update/auto-assign`, checked, config);
            if (status === 200) {
                await users()
                enqueueSnackbar(`${message}`, {variant: 'success'})
            }
        } catch (e) {
            if (e.response.status === 401) {
                localStorage.clear();
                navigate("/sign-in");
            }
            enqueueSnackbar(`${e.response.data.message}`, {variant: 'error'})
            setSuccess(true);
        }
    };

    React.useEffect(() => {
        checked?.id && AutoAssign()
    }, [checked?.id, checked?.checked]);

    return (
        <Box sx={{flex: 1, width: '100%'}}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography level="h3" component="h1" sx={{mt: 1, mb: 2}}>
                    Users
                </Typography>
                <Box>
                    <Button onClick={() => setOpen(true)}>Add</Button>
                </Box>
            </Box>
            <Box sx={{flex: 1}}>
                <DynamicTable columns={columns} count={rows?.total} rows={rows?.rows} perPage={limit} success={success}
                              checkBox={false}
                              hadName={""} type={"user"} setPagination={handlePaginatiopn}/>
            </Box>
            {open &&
            <UserDialog onClose={() => setOpen(false)} open={open} user={user} setUser={setUser} rerender={users}/>}
        </Box>
    )
}