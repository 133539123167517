import React from 'react';
import { Box, IconButton, Typography, Avatar } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideoCallIcon from '@mui/icons-material/VideocamOutlined';
import CallIcon from '@mui/icons-material/CallOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ChatHeader = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                borderBottom: '1px solid #ccc',
                backgroundColor: '#fff',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    src=""
                    alt="User Avatar"
                    sx={{ width: 40, height: 40, marginRight: '8px' }}
                />
                <Box>
                    <Typography level="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                        John goe
                    </Typography>
                    <Typography level="body2" sx={{ fontSize: '12px', color: '#888' }}>
                        last seen today at 12:48 pm
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton variant="plain"  sx={{marginRight: '8px', borderRadius: "50%"}}>
                    <VideoCallIcon />
                </IconButton>
                <IconButton variant="plain"  sx={{marginRight: '8px', borderRadius: "50%"}}>
                    <CallIcon />
                </IconButton>
                <IconButton variant="plain" sx={{borderRadius: "50%"}}>
                    <MoreVertIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ChatHeader;